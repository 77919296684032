/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex, Box, Text } from 'rebass'

export default ({ title, children }) => (
  <Box sx={{
    h2: { fontWeight: '800' },
    px: 4, pt: 3, pb: 4, mx: 2,
    my: 4,
    backgroundColor: 'highlight',
    borderRadius: 8
  }} width={[1, 4/10]}>
    <h2>{title}</h2>
    <Text>{children}</Text>
  </Box>
)
