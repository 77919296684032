/** @jsx jsx */
import { jsx, ThemeProvider, Container } from "theme-ui"

// const gradient = `linear-gradient(120deg,
//   rgba(230, 59, 25, .5),
//   rgba(51, 51, 238, 0),
//   rgba(51, 51, 238, .25))`

export default props => (
  <ThemeProvider
    theme={{
      styles: {
        h1: {
          variant: "text.display",
          fontSize: [4, 5, 6],
          // fontWeight: 'bold',
          mt: 0,
          mb: 3,
        },
        p: {
          fontSize: [2, 2, 2],
          letterSpacing: "initial",
          mt: 0,
          mb: 3,
        },
        a: {
          display: "inline-block",
          textDecoration: "none",
          fontWeight: 500,
          fontSize: 2,
          color: "primary",
          mr: 3,
          mb: 3,
        },
      },
    }}
  >
    <div
      sx={{
        pb: [5, 4],
        pt: [4, 3],
        mb: [-4, -4],
        color: "text",
        bg: props.bg || "background",
      }}
    >
      <Container>{props.children}</Container>
    </div>
  </ThemeProvider>
)
