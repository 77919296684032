/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from "react"
import { Button } from "rebass"
import { Link } from "gatsby"

export default (props) => (
  <Link to={props.to}>
    <Button>{props.children}</Button>
  </Link>
)
