import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Banner from '../components/banner.js';
import LinkButton from '../components/link-button.js';
import { Container } from 'theme-ui';
import Contacts from '../components/contact-cards';
import Tiles from '../components/tiles';
import { Flex, Box } from 'rebass';
import Card from '../components/card';
import Partner from '../components/partner-box';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Banner mdxType="Banner">
      <h2 {...{
        "id": "electrical--computer-wholesaler"
      }}>{`Electrical & Computer Wholesaler`}</h2>
      <p>{`LTS endeavours to provide a complete range of electrical & computer solutions, in the most convenient way possible. We strive to continuously supply retail and industrial customers with practical and affordable solutions to all their electrical & computer needs.`}</p>
      <p>{`We aspire to be a leading electrical and computer wholesaler, where customers consider us
first for top quality products, excellent customer service, convenience and competitive prices.`}</p>
      <LinkButton to="#contact-us" mdxType="LinkButton">Contact</LinkButton>
    </Banner>
    <Container mdxType="Container">
      <h2 {...{
        "id": "partners"
      }}>{`Partners`}</h2>
      <p>{`We supply a wide range of products from various renowned brands, including but not limited, to the following:`}</p>
      <Flex justifyContent="space-around" flexWrap='wrap' mdxType="Flex">
			<Partner to='https://www.asconumatics.eu/af-ZA/' src='asco_200x200.jpg' mdxType="Partner" />
			<Partner to='https://cbi-lowvoltage.co.za' src='cbi_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.centsys.co.za/' src='centurion_200x200.jpg' mdxType="Partner" />
			<Partner to='http://www.eaton.co.za/EatonSA/index.htm' src='eaton_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.flender.com/en' src='flender_200x200.jpg' mdxType="Partner" />
			<Partner to='http://www.krausandnaimer.co.za/' src='kn_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.legrand.co.za/' src='legrand_200x200.jpg' mdxType="Partner" />
			<Partner to='https://industrial.omron.co.za/en/home' src='omron_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.voltex.co.za/phambili_interface/' src='phambili_weidmuller_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.phoenixcontact.com/online/portal/za?1dmy&urile=wcm:path:/zaen/web/home' src='phoenix_contact_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.se.com/za/en/all-products/' src='schneider_200x200.jpg' mdxType="Partner" />
			<Partner to='https://new.siemens.com/za/en.html' src='siemens_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.socomec.com/Home_en.html' src='socomec_200x200.jpg' mdxType="Partner" />
			<Partner to='http://www.tempcon.co.za/' src='tempcon_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.testo.com/en-ZA/?gclid=EAIaIQobChMI26f0junM4gIV6L3tCh16rQEZEAAYASAAEgKh5vD_BwE' src='testo_200x200.jpg' mdxType="Partner" />
			<Partner to='https://www.zestweg.com/' src='zest_weg_200x200.jpg' mdxType="Partner" />
		</Flex>
      <h2 {...{
        "id": "about-us"
      }}>{`About Us`}</h2>
      <p>{`Since 1991, Lichtenburg Technical Services has been supplying Lichtenburg and
the surrounding areas with a wide range of electrical equipment and computer
hardware, software and consumables, all from a wide range of well-known brands.`}</p>
      <p>{`The company is known for its premium brands and vast product range that allows ample choice for all target markets. Our principles are customer driven, as we constantly source the best products, at competitive prices.`}</p>
      <p>{`LTS regards itself as a definite ethically sound business, whose practice ensures that the highest standards are maintained.`}</p>
      <p>{`The business activities are divided into two specialised supply and distribution divisions: `}</p>
      <Flex justifyContent="space-around" flexWrap='wrap' mdxType="Flex">
	<Card title="Electrical wholesale" mdxType="Card">LTS is an electrical wholesaler of a 
comprehensive range of electrical, industial and lighting products, 
including local and international suppliers and their brands.</Card>
	<Card title="Computer hardware" mdxType="Card">LTS is a stockist and reseller of all computer hardware, software, storage devices and consumables.</Card>
      </Flex>
      <h2 {...{
        "id": "contact-us"
      }}>{`Contact us`}</h2>
      <Contacts columns={2} mdxType="Contacts">
        <ul>
          <li parentName="ul"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2041.7970311341035!2d26.159841586443108!3d-26.15387373767937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e97d8980c0583e9%3A0x8bcb1ec48c5510c7!2sLichtenburg%20Technical%20Services!5e0!3m2!1sen!2sza!4v1574639513669!5m2!1sen!2sza" width="335" height="335" frameBorder="0" style={{
              "border": "0"
            }} allowFullScreen="" /></li>
          <li parentName="ul"><h3 parentName="li" {...{
              "id": "lichtenburg-technical-services"
            }}>{`Lichtenburg Technical Services`}</h3>{`36 Nelson Mandela Drive, `}<br />{`
Lichtenburg, 2740`}<br />{`
Tel: 018 632 1531`}<br />{`
Fax: 018 632 1538`}<br />{`
Email: `}<a parentName="li" {...{
              "href": "mailto:accounts@lts.net.za"
            }}>{`accounts@lts.net.za`}</a><br /><br />
            <br />
Business hours:<br />
Monday - Friday: 08:00 - 17:00<br />
Saturday: 08:00 - 12:00<br />
Sunday: Closed<br /></li>
        </ul>
      </Contacts>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      